import { CollectionsGalleryWidgetComponentIds } from '../../collectionsGalleryWidget/config/constants';
import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';

export enum FeaturedCollectionsWidgetComponentIds {
  Root = '#featuredCollectionWidgetRoot',
  MultiStateWidget = '#collectionMsbWidget1',
}

export enum FeaturedCollectionsWidgetEvents {}
// Change = 'change',

export const FeaturedCollectionWidgetTabsArray = [
  {
    label: 'Featured_Collection_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Featured_Collection_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // remove till pro-gallery team will fix the issue
  // {
  //   label: 'Featured_Collection_Design_Panel_Projects_Gallery',
  //   roles: [
  //     CollectionWidgetComponentIds.Gallery,
  //     CollectionsGalleryWidgetComponentIds.GalleyWidget,
  //   ],
  // },
  {
    label: 'Portfolio_Design_Panel_Page_Background',
    roles: [FeaturedCollectionsWidgetComponentIds.Root],
  },
];
