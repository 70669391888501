import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { FeaturedCollectionWidgetWidgetData } from './config/types';

const DEFAULT_DATA = {
  selectedCollections: undefined,
} as FeaturedCollectionWidgetWidgetData;

export default createBlocksModel({
  widgetName: 'FeaturedCollectionWidget',
  props: {
    data: {
      type: {} as any,
      defaultValue: DEFAULT_DATA,
    },
  },
  methods: {},
  events: {},
});
