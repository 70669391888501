// import { warmupData } from 'wix-window';

export class WarmupDataManager {
  warmupData: WarmupData;
  environment: Environment;
  isExperimentEnabled: boolean;

  constructor(
    warmupData: WarmupData,
    environment: Environment,
    isExperimentEnabled?: boolean,
  ) {
    this.warmupData = warmupData;
    this.environment = environment;
    this.isExperimentEnabled = isExperimentEnabled || false;
    this.manageData = this.manageData.bind(this);
  }
  async manageData(callback: any, key: string) {
    let res;
    if (!this.environment.isSSR) {
      res = this.warmupData.get(key);
    }
    if (!res) {
      res = callback();
      if (this.environment.isSSR) {
        res.then((result: any) => {
          this.warmupData.set(key, result);
        });
      }
    }
    return res;
  }
}
