export const AppDefId = 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130';
export const defaultHelpId = 'c528b8c5-3efa-4ad5-aa44-18b40f839170';
export enum MainWidgetsIds {
  PORTFOLIO_PAGE = 'ccd857ff-153e-452c-8cea-3f3a8aafeb86',
  COLLECTION_PAGE = 'd6c045f0-5d83-4c6c-845e-9059ca159990',
  PROJECT_PAGE = '437cf52e-f57d-449e-af48-96791677e471',
  FEATURE_COLLECTION = 'ceecd61c-3aec-4639-b32c-cbfe1400e819',
}
export enum COLLECTION_PAGE_AUTO_FOCUS_TARGETS {
  TITLE = 'title',
  DESCRIPTION = 'description',
  PROJECTS = 'projects',
}
export enum PROJECT_PAGE_AUTO_FOCUS_TARGETS {
  TITLE = 'title',
  DESCRIPTION = 'description',
  COVER = 'cover',
  PROJECT_MEDIA = 'project_media',
  DETAILS_LABEL = 'details_label',
  DETAILS_TEXT = 'details_text',
}

export const PROJECT_ITEM_PAGING_LIMIT = 100;
