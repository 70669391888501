import preset1 from '../../../assets/media/collections-portfolio-presets/1.png';
import preset2 from '../../../assets/media/collections-portfolio-presets/2.png';
import preset3 from '../../../assets/media/collections-portfolio-presets/3.png';
import preset4 from '../../../assets/media/collections-portfolio-presets/4.png';
import preset5 from '../../../assets/media/collections-portfolio-presets/5.png';
import preset6 from '../../../assets/media/collections-portfolio-presets/6.png';
import preset7 from '../../../assets/media/collections-portfolio-presets/7.png';
import preset8 from '../../../assets/media/collections-portfolio-presets/8.png';
import preset9 from '../../../assets/media/collections-portfolio-presets/9.png';
import preset10 from '../../../assets/media/collections-portfolio-presets/10.png';
import preset11 from '../../../assets/media/collections-portfolio-presets/11.png';
import preset12 from '../../../assets/media/collections-portfolio-presets/12.png';
import preset13 from '../../../assets/media/collections-portfolio-presets/13.png';
import preset14 from '../../../assets/media/collections-portfolio-presets/14.png';
import preset15 from '../../../assets/media/collections-portfolio-presets/15.png';
import preset16 from '../../../assets/media/collections-portfolio-presets/16.png';
import preset17 from '../../../assets/media/collections-portfolio-presets/17.png';
import preset18 from '../../../assets/media/collections-portfolio-presets/18.png';
import preset19 from '../../../assets/media/collections-portfolio-presets/19.png';
import preset20 from '../../../assets/media/collections-portfolio-presets/20.png';
import preset21 from '../../../assets/media/collections-portfolio-presets/21.png';
import preset22 from '../../../assets/media/collections-portfolio-presets/22.png';
import preset23 from '../../../assets/media/collections-portfolio-presets/23.png';
import preset24 from '../../../assets/media/collections-portfolio-presets/24.png';

import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';
import { NavigationWidgetComponentIds } from '../../navigationWidget/config/constants';

export enum CollectionPageWidgetComponentIds {
  Root = '#collectionPageWidgetRoot',
  CollectionWidget = '#collectionWidget',
  NavigationWidget = '#navigationWidget',
  EmptyStateWidget = '#collectionEmptyStateWidget1',
}
export enum CollectionPageWidgetEvents { }

export const CollectionPageWidgetTabsArray = [
  {
    label: 'Collection_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Collection_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Collection_Design_Panel_Projects_Gallery',
  //   roles: [CollectionWidgetComponentIds.Gallery],
  // },
  {
    label: 'Collection_Design_Panel_Back_Button',
    roles: [NavigationWidgetComponentIds.BackButtonWidget],
    skipOnEditorX: true,
  },
  {
    label: 'Collection_Design_Panel_Page_Background',
    roles: [CollectionPageWidgetComponentIds.Root],
    skipOnEditorX: true,
  },
];

export const CollectionPageWidgetPresets = [
  {
    id: 'externalPreset-variants-lbxcjmbu',
    connectedMobilePresetID: 'variants-lbxcklxe',
    src: preset1,
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5hrjm',
    connectedMobilePresetID: 'variants-lbc5mkrs',
    src: preset2,
    layout: {
      width: '114px',
      height: '102px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8zgd8',
    connectedMobilePresetID: 'variants-lbc90m76',
    src: preset3,
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5obj1',
    connectedMobilePresetID: 'variants-lbc5su2o',
    src: preset4,
    layout: {
      width: '114px',
      height: '101px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5xfvq',
    connectedMobilePresetID: 'variants-lbc5xb9y',
    src: preset5,
    layout: {
      width: '114px',
      height: '141px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8143t',
    connectedMobilePresetID: 'variants-lbc81zj8',
    src: preset6,
    layout: {
      width: '114px',
      height: '141px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5v6mw',
    connectedMobilePresetID: 'variants-lbc5v1iy',
    src: preset7,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc5z9yi',
    connectedMobilePresetID: 'variants-lbc5z4w7',
    src: preset8,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc82x7k',
    connectedMobilePresetID: 'variants-lbc83tfx',
    src: preset9,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc64w40',
    connectedMobilePresetID: 'variants-lbc64nay',
    src: preset10,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8x3im',
    connectedMobilePresetID: 'variants-lbc8yc96',
    src: preset11,
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8m4re',
    connectedMobilePresetID: 'variants-lbc8n8zv',
    src: preset12,
    layout: {
      width: '114px',
      height: '97px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8iiiz',
    connectedMobilePresetID: 'variants-lbc8l4ze',
    src: preset13,
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lbxc0siz',
    connectedMobilePresetID: 'variants-lbxc1tvv',
    src: preset14,
    layout: {
      width: '114px',
      height: '113px',
    },
  },
  {
    id: 'externalPreset-variants-lbxbxc2r',
    connectedMobilePresetID: 'variants-lbxby647',
    src: preset15,
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8ubcd',
    connectedMobilePresetID: 'variants-lbc8w03b',
    src: preset16,
    layout: {
      width: '114px',
      height: '85px',
    },
  },
  {
    id: 'externalPreset-variants-lbc7z56y',
    connectedMobilePresetID: 'variants-lbc805kh',
    src: preset17,
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lbc685rw',
    connectedMobilePresetID: 'variants-lbc67zat',
    src: preset18,
    layout: {
      width: '114px',
      height: '95px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8o9d8',
    connectedMobilePresetID: 'variants-lbc8tcmx',
    src: preset19,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc84yzn',
    connectedMobilePresetID: 'variants-lbc896eb',
    src: preset20,
    layout: {
      width: '114px',
      height: '106px',
    },
  },
  {
    id: 'externalPreset-variants-lbc91ubo',
    connectedMobilePresetID: 'variants-lbc92ymt',
    src: preset21,
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8a4c1',
    connectedMobilePresetID: 'variants-lbc8axec',
    src: preset22,
    layout: {
      width: '114px',
      height: '108px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8buq0',
    connectedMobilePresetID: 'variants-lbc8epqe',
    src: preset23,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
  {
    id: 'externalPreset-variants-lbc8gbqm',
    connectedMobilePresetID: 'variants-lbc8hfa8',
    src: preset24,
    layout: {
      width: '114px',
      height: '98px',
    },
  },
];
