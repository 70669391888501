import model from './model';
import { ProjectWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectWidgetProps as Props } from './config/types';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import {
  getCollectionSlugFromUrl,
  getUrlNextNPrevProject,
} from '../../utils/urlsUtils';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const { isEditor } = flowAPI.environment;
  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectHeaderWidget: $w(ComponentIds.Header),
    gallery: $w(ComponentIds.Gallery),
    navigationWidget: $w(ComponentIds.NavigationWidget),
  });

  const getWixCodePGItems = (projectItems: Item[]) => {
    return commonImagesToWixCode(projectItems);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.project?.id) {
      components.projectHeaderWidget.data = {
        title: data.project.title,
        details: data.project?.details,
        description: data.project.description,
        heroImage: data.project.coverImage,
      };
      components.gallery.items = getWixCodePGItems(data.items);
    }
    if (isEditor) {
      components.navigationWidget.data = {
        hideNextButton: false,
        hideBackButton: false,
      };
    } else {
      components.navigationWidget.data = {
        hideNextButton: !data.nextProjectSlug,
        hideBackButton: !data.prevProjectSlug,
      };
    }
  };

  const handleInteractions = (prevProjectSlug, nextProjectSlug) => {
    if (!isEditor) {
      if (prevProjectSlug) {
        const previousProjectUrl = getUrlNextNPrevProject(
          flowAPI,
          getCollectionSlugFromUrl(flowAPI),
          prevProjectSlug,
        );
        components.navigationWidget?.onBackClicked(() => {
          flowAPI?.controllerConfig?.wixCodeApi?.location?.to &&
            flowAPI?.controllerConfig?.wixCodeApi?.location?.to(
              previousProjectUrl,
            );
        });
      }

      if (nextProjectSlug) {
        const nextProjectUrl = getUrlNextNPrevProject(
          flowAPI,
          getCollectionSlugFromUrl(flowAPI),
          nextProjectSlug,
        );
        components.navigationWidget?.onNextClicked(() => {
          flowAPI?.controllerConfig?.wixCodeApi?.location?.to &&
            flowAPI?.controllerConfig?.wixCodeApi?.location?.to(nextProjectUrl);
        });
      }
    }
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    const isWidgetOnStage = !!components.topContainer.id;
    if (isWidgetOnStage) {
      handleInteractions(
        nextProps.data.prevProjectSlug,
        nextProps.data.nextProjectSlug,
      );
      initByProps(nextProps);
    }
  });
  return {
    pageReady: async () => {},
    exports: {},
  };
});
