import model from './model';
import {
  NavigationWidgetComponentIds as ComponentIds,
  NavigationWidgetEvents as Events,
} from './config/constants';
import { NavigationWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    nextButtonWidget: $w(ComponentIds.NextButtonWidget),
    backButtonWidget: $w(ComponentIds.BackButtonWidget),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.hideBackButton) {
      components?.backButtonWidget?.hide();
    } else {
      components?.backButtonWidget?.show();
    }

    if (data.hideNextButton) {
      components?.nextButtonWidget?.hide();
    } else {
      components?.nextButtonWidget?.show();
    }
  };

  const initEventHandlers = () => {
    components.backButtonWidget?.onNavigationButtonClicked(() => {
      $widget.fireEvent(Events.backClicked, {});
    });

    components.nextButtonWidget?.onNavigationButtonClicked(() => {
      $widget.fireEvent(Events.nextClicked, {});
    });
  };

  $widget.onPropsChanged((_, nextProps) => {
    components = getComponents();
    initByProps(nextProps);
  });

  return {
    pageReady: async () => {
      components = getComponents();
      const isWidgetOnStage = !!components.topContainer.id;
      if (isWidgetOnStage) {
        initEventHandlers();
      }
    },
    exports: {},
  };
});
